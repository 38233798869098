import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import contentPT from '../content/giveaway/contentPT';
import contentBR from '../content/giveaway/contentBR';
import LanguageContext from '../context/LanguageContext';

const GiveawayPage = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.5rem 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const GiveawayContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const GiveawayHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`;

const GiveawayTitle = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-size: 2.5vw;
  font-weight: 600;
  color: #2980b9;
  margin-top: 1.5rem;
  padding-top: 2.5rem;

  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const GiveawayContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

const Section = styled.div`
  margin-bottom: 1rem;
`;

const SectionTitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-size: 1.8vw;
  color: #2980b9;
  margin-bottom: 0.5rem;

@media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const SectionContent = styled.p`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3vw;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const Giveaway = () => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;

  return (
    <GiveawayPage>
      <Helmet>
        <title>{content.seo.title}</title>
        <meta name="description" content={content.seo.description} />
        <meta name="keywords" content={content.seo.keywords} />
        <meta property="og:title" content={content.seo.title} />
        <meta property="og:description" content={content.seo.description} />
        <meta property="og:image" content={content.seo.image} />
        <meta property="og:url" content="https://clubedevoos.com/sorteio" />
        <link rel="canonical" href="https://clubedevoos.com/sorteio" />
      </Helmet>
      
      <GiveawayContainer>
        <GiveawayHeader>
          <GiveawayTitle>{content.title} <Highlight>{content.highlight}</Highlight></GiveawayTitle>
        </GiveawayHeader>
        <GiveawayContent>
          <Section>
            <SectionTitle>{content.eligibility.heading}</SectionTitle>
            <SectionContent>
            {content.eligibility.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.period.heading}</SectionTitle>
            <SectionContent>
            {content.period.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.prize.heading}</SectionTitle>
            <SectionContent>
            {content.prize.text}
            </SectionContent>
            <SectionContent>
            {content.prize.feat1}
            </SectionContent>
            <SectionContent>
            {content.prize.feat2}
            </SectionContent>
            <SectionContent>
            {content.prize.extra}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.selection.heading}</SectionTitle>
            <SectionContent>
            {content.selection.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.notification.heading}</SectionTitle>
            <SectionContent>
            {content.notification.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.limitation.heading}</SectionTitle>
            <SectionContent>
            {content.limitation.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.privacy.heading}</SectionTitle>
            <SectionContent>
            {content.privacy.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.exemptions.heading}</SectionTitle>
            <SectionContent>
            {content.exemptions.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.closing.heading}</SectionTitle>
            <SectionContent>
            {content.closing.text}
            </SectionContent>
          </Section>

        <GiveawayHeader>
          <GiveawayTitle>{content.titlereferral} <br/><Highlight>{content.highlight}</Highlight></GiveawayTitle>
        </GiveawayHeader>

          <Section>
            <SectionTitle>{content.eligibilityreferral.heading}</SectionTitle>
            <SectionContent>
            {content.eligibilityreferral.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.howto.heading}</SectionTitle>
            <SectionContent>
            {content.howto.list1}
            </SectionContent>
            <SectionContent>
            {content.howto.list2}
            </SectionContent>
            <SectionContent>
            {content.howto.list3}
            </SectionContent>
            <SectionContent>
            {content.howto.list4}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.prizereferral.heading}</SectionTitle>
            <SectionContent>
            {content.prizereferral.feat1}
            </SectionContent>
            <SectionContent>
            {content.prizereferral.feat2}
            </SectionContent>
            <SectionContent>
            {content.prizereferral.feat3}
            </SectionContent>
            <SectionContent>
            {content.prizereferral.feat4}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.limitationsreferral.heading}</SectionTitle>
            <SectionContent>
            {content.limitationsreferral.feat1}
            </SectionContent>
            <SectionContent>
            {content.limitationsreferral.list1}
            </SectionContent>
            <SectionContent>
            {content.limitationsreferral.feat2}
            </SectionContent>
            <SectionContent>
            {content.limitationsreferral.list2}
            </SectionContent>
            <SectionContent>
            {content.limitationsreferral.feat3}
            </SectionContent>
            <SectionContent>
            {content.limitationsreferral.feat4}
            </SectionContent>
          </Section>
          
          <Section>
            <SectionTitle>{content.fraud.heading}</SectionTitle>
            <SectionContent>
            {content.fraud.text}
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>{content.changes.heading}</SectionTitle>
            <SectionContent>
            {content.changes.text}
            </SectionContent>
          </Section>

        </GiveawayContent>
      </GiveawayContainer>
    </GiveawayPage>
  );
};

export default Giveaway;
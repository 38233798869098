//contentBR
const contentBR = {
  seo: {
    title: "Dicas para Viajantes | Clube de Voos",
    description: "Encontre dicas úteis para viajantes e descubra como economizar em suas viagens com o Clube de Voos.",
    keywords: "dicas de viagem, economizar em viagens, Clube de Voos",
    image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FClube%20de%20Voos%20Logo.png?alt=media&token=00ada67a-4721-4ba9-8dd0-9ba89da9d46e",
  },
  link: "https://clube-de-voos.formaloo.me/aeroportos",
}
  
  export default contentBR;
  
// contentBR.js

const contentBR = {
    intro: "Bem-vindo(a) ao Clube De Voos. Estamos comprometidos em proteger suas informações pessoais e seu direito à privacidade. Esta Política de Privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações ao utilizar nosso serviço.",
    info: {
      heading: "2. Informações que Coletamos",
      text: "Coletamos as seguintes informações:",
      list: "Incluindo seu aeroporto de origem, épocas de viagem preferidas, destinos, companhias aéreas, orçamentos e atividades.",
    },
    collection: {
      heading: "3. Como Coletamos as Informações",
      list1: "Diretamente quando você se inscreve no nosso serviço.",
      list2: "Quando você completa seu perfil de viajante (para serviço Premium).",
    },
    usage: {
      heading: "4. Como Utilizamos Suas Informações",
      text: "Utilizamos suas informações para:",
      list1: "Enviar nossa newsletter e alertas de viagem.",
      list2: "Personalizar recomendações de viagem com base nas suas preferências.",
      list3: "Verificar sua identidade e gerenciar sua conta (para serviço Premium).",
      list4: "Aprimorar nossos serviços e experiência do usuário.",
    },
    payment: {
      heading: "5. Informações de Pagamento",
      text: "Todo o processamento de pagamentos para assinaturas Premium é gerido pela Stripe. Não coletamos, armazenamos ou processamos quaisquer informações de pagamento em nossos servidores."
    },
    retention: {
      heading: "6. Retenção e Exclusão de Dados",
      text: "Retemos suas informações apenas pelo tempo necessário para fornecer nossos serviços. Se optar por cancelar sua assinatura, excluiremos todas as informações que temos sobre você de nossos registros."
    },
    security: {
      heading: "7. Segurança dos Dados",
      text: "Implementamos medidas técnicas e organizacionais apropriadas para proteger suas informações pessoais contra processamento não autorizado ou ilegal, perda acidental, destruição ou danos."
    },
    cookies: {
      heading: "8. Cookies e Tecnologias Semelhantes",
      text: "Não usamos cookies para gerenciar as sessões dos nossos usuários ou para armazenar quaisquer de suas preferências. Não usamos cookies, ponto final."
    },
    rights: {
      heading: "9. Seus Direitos",
      text: "Você tem o direito de:",
      list1: "Acessar as informações pessoais que temos sobre você.",
      list2: "Corrigir quaisquer imprecisões em suas informações pessoais.",
      list3: "Solicitar a exclusão de suas informações pessoais.",
      list4: "Optar por não receber comunicações de marketing.",
    },
    changes: {
      heading: "10. Alterações a Esta Política de Privacidade",
      text: "Podemos atualizar esta política de privacidade periodicamente. Quaisquer alterações serão publicadas nesta página na seção 'Última Atualização' e enviaremos uma notificação a todos os assinantes ativos sempre que houver uma mudança.",
    },
    update: "11. Última Atualização",
    contact: {
      heading: "12. Contate-nos",
      text: "Se tiver dúvidas sobre estes T&Cs ou Política de Privacidade ou desejar exercer seus direitos para solicitar, excluir ou atualizar seus dados, por favor, entre em contato conosco em:",
    },
    tctitle: "Termos e Condições (T&Cs)",
    refunds: {
    heading: "1. Assinaturas e Reembolsos:",
    list1: "Não oferecemos reembolsos para assinaturas Premium, mas disponibilizamos um período de teste de 14 dias (apenas para o plano vitalício) para que você experimente nossos serviços antes de decidir.",
    list2: "Disponibilizamos uma assinatura vitalícia gratuita para testar nossos serviços."
    },
    mkt: {
    heading: "2. Uso de Dados por Parceiros:",
    list1: "Dados de assinantes Premium podem ser compartilhados com parceiros para podermos oferecer benefícios Premium.",
    list2: "Assinantes gratuitos podem solicitar exclusão desses compartilhamentos.",
    },
    promos: {
    heading: "3. Promoções e Benefícios:",
    list1: "Benefícios exclusivos dos nossos parceiros são reservados para assinantes Premium.",
    },
    airports: {
    heading: "4. Cadastro e Alterações:",
    list1: "Cada email pode ser vinculado a um único aeroporto.",
    list2: "Assinantes Premium podem atualizar ilimitadamente o seu aeroporto de origem. Intervalos entre mudanças podem ser adicionados no futuro."
    },
    tcprices: {
    heading: "5. Disponibilidade de Preços",
    text: "Os preços exibidos nas ofertas do Clube de Voos refletem os valores disponíveis no momento da publicação. Contudo, como os preços de passagens aéreas podem mudar rapidamente, especialmente em casos de tarifas promocionais ou erros de tarifas (error fares), é importante verificar a disponibilidade o quanto antes.",
    list1: "Tarifas promocionais: Algumas ofertas possuem limite de assentos e podem esgotar em poucas horas.",
    list2: "Error fares: Por serem erros pontuais das companhias aéreas, essas tarifas geralmente são corrigidas rapidamente e ficam disponíveis por pouco tempo. Recomendamos que decidas com agilidade e acompanhes nossas notificações para aproveitar as melhores oportunidades.",
    },
    tcchanges: {
    heading: "6. Alterações nos Termos:",
    list1: "O Clube De Voos pode alterar os Termos, informando previamente os usuários.",
    },
  };
  
  export default contentBR;
  
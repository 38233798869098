//contentBR
const contentBR = {
    buttonaria: "Cadastre-se para receber ofertas de voos gratuitos",
    buttonariaPremium: "Acesse as ofertas exclusivas para assinantes Premium",
    seo: {
      title: 'Clube de Voos | Ofertas Exclusivas de Viagens',
      description: 'Encontre as melhores ofertas de voos. Junte-se ao Clube de Voos e economize em suas próximas aventuras!',
      keywords: 'ofertas de voos,as viagens mais baratas, Clube de Voos',
      image: 'https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2FClube%20de%20Voos%20Logo.png?alt=media&token=00ada67a-4721-4ba9-8dd0-9ba89da9d46e',
    },
    title: "VOA CONOSCO",
    services: "Temos para você",
    herotext: "As melhores promoções de passagens aéreas, direto no seu email",
    herotext2: "Ofertas personalizadas para voar mais e gastar menos",
    ofertasexclusivas: {
      heading: "Ofertas Exclusivas de Voos",
      text: "Receba acesso a ofertas de voos exclusivas na sua caixa de email."
    },
    promoções: {
      heading: "Promoções Personalizadas (Exclusivo para Membros Premium)",
      text: "Promoções de voos personalizadas com base nas preferências de viagens: Destinos; Datas; Acontecimentos."
    },
    acesso: {
      heading: "Acesso Antecipado a Ofertas (Exclusivo para Membros Premium)",
      text: "Receba notificações sobre ofertas de voos antes de serem disponibilizadas aos demais assinantes."
    },
    tarifas: {
      heading: "Tarifas de Erro e Descontos de Última Hora (Premium)",
      text: "Alertas sobre tarifas de erro raras e descontos significativos de última hora."
    },
    opções: {
      heading: "Opções Acessíveis A Todos",
      text: "Aderir ao Clube de Voos é grátis! Assinantes Premium podem aderir por apenas 45€ por ano ou um pagamento único de 99€ para acesso vitalício a todos os benefícios Premium."
    },
  };
  
export default contentBR;

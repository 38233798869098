// src/App.js
import React, { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from './firebaseConfig';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import LandingLayout from './components/LandingLayout';
import Homepage from './pages/Homepage';
import PerguntasFrequentes from './pages/PerguntasFrequentes';
import Dicas from './pages/Dicas';
import BlogPost from './pages/BlogPost';
import Subscribe from './pages/Subscribe';
import CompleteProfile from './pages/CompleteProfile';
import SecureProfilePage from './pages/SecureProfilePage';
import PrivateRoute from './components/PrivateRoute';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PremiumCurrentOffers from './pages/PremiumCurrentOffers';
import LandingPageBR from './pages/LandingPageBR';
import LandingPagePT from './pages/LandingPagePT';
import Giveaway from './pages/Giveaway';

function App() {
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && user.emailVerified) {
        try {
          const userDocRef = doc(firestore, 'subscribers', user.uid);
          await updateDoc(userDocRef, {
            isVerified: true,
            updatedAt: new Date(),
          });
          console.log('User email verified and Firestore updated');
        } catch (error) {
          console.error('Error updating Firestore:', error);
        }
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* Routes using the main Layout */}
          <Route element={<Layout />}>
            <Route path="/" element={<Homepage />} />
            <Route path="/perguntas-frequentes" element={<PerguntasFrequentes />} />
            <Route path="/dicas-de-viagens" element={<Dicas />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/subscreve" element={<Subscribe />} />
            <Route path="/tcs-e-privacidade" element={<PrivacyPolicy />} />
            <Route path="/sorteio-promocoes" element={<Giveaway />} />
            <Route path="/complete-profile" element={<CompleteProfile />} />
            <Route path="/area-pessoal" element={<PrivateRoute><SecureProfilePage /></PrivateRoute>} />
            <Route path="/ofertas-premium" element={<PrivateRoute><PremiumCurrentOffers /></PrivateRoute>} />
            <Route path="/unauthorized" element={<div>Unauthorized Access</div>} />
          </Route>

          {/* Routes using LandingLayout without header */}
          <Route path="/br" element={<LandingLayout><LandingPageBR /></LandingLayout>} />
          <Route path="/pt" element={<LandingLayout><LandingPagePT /></LandingLayout>} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Carousel from '../components/Carousel';
import Accordion from '../components/Accordion';
import MainCurrentOffers from '../components/MainCurrentOffers';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import contentPT from '../content/subscribe/contentPT';
import contentBR from '../content/subscribe/contentBR';
import LanguageContext from '../context/LanguageContext';
import image1 from '../images/greece.webp';
import image2 from '../images/iceland.webp';
import image3 from '../images/cave.webp';
import image4 from '../images/nova-iorque.webp';

const SubscribePage = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Hero = styled.section`
  position: relative;
  width: 100%;
  height: 40rem;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 25rem;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const SubscribeContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  padding: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
`;

const FormContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -3rem;
  margin-bottom: 2rem;
  padding: 0.3rem;
  font-family: 'Poppins';
  font-size: 1.5vw;
  color: white;

  @media (max-width: 768px) {
  font-size: 3vh; /* Increase font size */
  }
`;

const PremiumBenefits = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  color: white;
  margin-top: 5rem;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 80%;
    margin-top: 1rem;
  }
`;

const ConfirmationPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 30rem;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #2980B9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 1000;
`;

const PopupContent = styled.div`
  text-align: center;
`;

const PremiumLink = styled.a`
  font-family: 'Nunito';
  font-size: 1rem;
  color: #2980B9;
  text-decoration: underline;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const PriceTableHeading = styled.h3`
  font-size: 4.5rem;
  font-family: 'Poppins';
  text-align: center;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 1px;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 0.5rem;
  color: #ff5733;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const StripePriceTable = styled.div`
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  iframe {
    width: 100%;
    border: none;
  }
`;

const Section = styled.section`
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  margin: 2rem 0;

  &.current-offers h2 {
    font-family: 'Nunito';
    font-size: 5vw;
    color: #2980b9;
  }

  @media (max-width: 768px) {
  margin: 0.2rem 0;
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const RoadmapSection = styled.section`
  text-align: center;
  margin-top: 3rem;
  h2 {
    font-family: 'Nunito';
    font-size: 5vw;
    color: #2980B9;
  }

  
  img {
    width: 70%; /* Adjust as necessary */
    height: auto;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.2rem;

    img {
      width: 100%; /* Increase size only on mobile */
    }
  }
`;

const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const SubscriptionInput = styled.input`
  width: 80%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Source Sans Pro';
  font-size: 1rem;
`;

const SubscriptionButton = styled.button`
  background-color: #ff5733;
  color: #fff;
  border: none;
  width: 86%;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.6rem;
  font-family: 'Nunito';
  font-weight: 700;
  letter-spacing: 0.05rem;

  &:hover {
    background-color: #f39c12;
  }
`;

const StyledSelect = styled.select`
  width: 86%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Source Sans Pro';
  font-size: 1rem;
  background-color: #fff;
`;

const departureAirports = [
  "LISBOA-PT",
  "PORTO-PT",
  "RIO DE JANEIRO-BR",
  "SÃO PAULO-BR",
];

const Subscribe = () => {
  const {language} = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [homeAirport, setHomeAirport] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.substring(1); // remove the '#' character
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, 'temporaryPassword');
      const user = userCredential.user;
      await sendEmailVerification(user);

      await setDoc(doc(firestore, 'subscribers', user.uid), {
        name,
        email,
        homeAirport,
        subscriptionType: 'free',
        isVerified: false,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      setShowPopup(true);
      setName('');
      setEmail('');
      setHomeAirport('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Ocorreu um erro. Por favor, tente novamente.');
    }
  };

  const subscribeImages = [
    {
      src: image1,
      alt: "Santorini",
      srcSet: `${image1} 500w, ${image1} 1000w, ${image1} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
    {
      src: image2,
      alt: "Iglo",
      srcSet: `${image2} 500w, ${image2} 1000w, ${image2} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
    {
      src: image3,
      alt: "Beach Cave",
      srcSet: `${image3} 500w, ${image3} 1000w, ${image3} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
    {
      src: image4,
      alt: "Nova Iorque",
      srcSet: `${image4} 500w, ${image4} 1000w, ${image4} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
  ];

  console.log('Subscribe Images:', subscribeImages);

  const accordionData = [
    {
      title: "Aeroportos",
      content: "Membros Premium podem atualizar o seu aeroporto de partida a qualquer momento na sua conta pessoal.",
      isOpen: true,
    },
    {
      title: "Programas de Fidelidade",
      content: " Os Membros Premium podem selecionar uma companhia aérea, e o Clube De Voos procura ofertas a preços excepcionais que podem ser pagas com pontos/milhas.",
    },
    {
      title: "Quero Mais",
      content: "Enquanto Membros Grátis recebem 2 ou 3 oportunidades por mês, os Membros Premium recebem não só muitas mais opportunidades mas também ofertas personalizadas para o seu perfil de viajante e 'Erros de Tarifa'.",
    },
    {
      title: "Mais Barato e é Grátis",
      content: "Basta comprar um voo para os Membros Premium receberem de volta até 10x do custo da subscrição anual. Se a promoção de preço único para a Assinatura Vitalícia ainda estiver em vigor então podemos dizer que é mais barata que Grátis.",
    },
  ];

  return (
    <SubscribePage>
      <Helmet>
        <title>{content.seo.title}</title>
        <meta name="description" content={content.seo.description} />
        <meta name="keywords" content={content.seo.keywords} />
        <meta property="og:title" content={content.seo.title} />
        <meta property="og:description" content={content.seo.description} />
        <meta property="og:image" content={content.seo.image} />
        <meta property="og:url" content="https://clubedevoos.com/subscribe" />
        <link rel="canonical" href="https://clubedevoos.com/subscribe" />
      </Helmet>
      
      <Hero>
        <Carousel imageUrls={subscribeImages} />
        <Overlay>
          <SubscribeContainer>
            <PremiumBenefits>
              <Accordion data={accordionData} />
            </PremiumBenefits>
          </SubscribeContainer>
        </Overlay>
      </Hero>
      {showPopup && (
        <ConfirmationPopup>
          <CloseButton onClick={() => setShowPopup(false)}>X</CloseButton>
          <PopupContent>
            <h2>Subscreve ao <Highlight>Clube de Voos</Highlight></h2>
            <p>{content.popup}</p>
            <PremiumLink href="/subscreve#price-table-heading" onClick={() => setShowPopup(false)}>
              Quero Premium!
            </PremiumLink>
          </PopupContent>
        </ConfirmationPopup>
      )}
      <PriceTableHeading id="price-table-heading">Subscripções Premium</PriceTableHeading>
      <StripePriceTable>
        <script src="https://js.stripe.com/v3/pricing-table.js" async></script>
        {
          <stripe-pricing-table
          pricing-table-id={content.tableid}
          publishable-key="pk_live_51PYi94H23jJ1vudC9HRdeNGHRh57YQzrN32vRmpz7xP3HQCiyijYaPnJgPaihbMdF7dFKJVO7oSKN95iUlzT3EZK008TKMTSHk"
        ></stripe-pricing-table>
        }
      </StripePriceTable>
      <Section className="current-offers">
            <MainCurrentOffers />
      </Section>
      <RoadmapSection>
        <h2><Highlight>Roadmap - </Highlight>Plano de Ação 2025/26</h2>
        <img 
          srcSet={`${require('../images/cdv-roadmap.webp')} 500w, ${require('../images/cdv-roadmap.webp')} 1000w`} 
          sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw" 
          src={require('../images/cdv-roadmap.webp')} 
          alt="Roadmap 2025/2026" 
          style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
        /> {/* Image with Roadmap */}
      </RoadmapSection>
      <FormContainer>
              <h2 className="subscribe-heading">Subscreve ao <br /><Highlight>Clube de Voos</Highlight></h2>
              <SubscriptionForm onSubmit={handleSubmit}>
                <SubscriptionInput 
                  type="text" 
                  placeholder="Nome" 
                  required 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <SubscriptionInput 
                  type="email" 
                  placeholder="Email" 
                  required 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <StyledSelect
                  value={homeAirport}
                  onChange={(e) => setHomeAirport(e.target.value)}
                  required
                >
                  <option value="">Aeroporto de Partida</option>
                  {departureAirports.map((airport, index) => (
                    <option key={index} value={airport}>{airport}</option>
                  ))}
                </StyledSelect>
                <SubscriptionButton type="submit">Subscreve (Grátis)</SubscriptionButton>
              </SubscriptionForm>
            </FormContainer>
    </SubscribePage>
  );
};

export default Subscribe;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import cancun from '../images/cancun.webp';
import havana from '../images/havana.webp';
import milan from '../images/milan.webp';

const CurrentOffersSection = styled.section`
  text-align: center;

  h2 {
    font-family: 'Poppins';
    font-size: 3vw;
    color: #2980B9;
    padding: 2rem;
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 5vw;
    }
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const OffersContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Space out the cards evenly */
  gap: 1rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack offers vertically on mobile */
    width: 100%;
    align-items: center;
    gap: 0.01rem;
  }
`;

const OfferCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px; /* Set card width */
  height: 520px; /* Set card height */
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 1rem;
  position: relative; /* Make the card container relative for positioning the text on top */

  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 768px) {
    width: 300px; /* Set card width */
    height: 420px; /* Set card height */
  }
`;

const OfferImage = styled.img`
  width: 100%;
  height: 100%; /* Ensure the image fills the entire card */
  object-fit: cover;
  border-radius: 15px;
  position: absolute; /* Position the image absolutely inside the card */
  top: 0;
  left: 0;
`;

const OfferTextWrapper = styled.div`
  position: absolute;
  bottom: 0; /* Position the text at the bottom */
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better visibility */
  color: white;
  text-align: center;
  border-radius: 0 0 15px 15px; /* Rounded corners for bottom part */
`;

const OfferTitle = styled.h3`
  font-family: 'Nunito';
  font-size: 1rem;
  font-weight: 600;
  color: white;
  margin: 0;
  text-align: center;
`;

const OfferDescription = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 2rem;
  font-weight: 500;
  color: #ddd;
  text-align: center;
  margin-top: 0.5rem;
`;

const MainCurrentOffers = () => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthorized(!!user); // Set true if user exists, false otherwise
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const handleClick = (offer) => {
    if (isAuthorized) {
      // Redirect to external URL
      window.open(offer.link, '_blank');
    } else {
      // Redirect to premium offers page
      navigate('/ofertas-premium');
    }
  };

  const offers = [
    {
      id: "cancun",
      image: cancun,
      destination: "Cancún",
      details: "Ida e Volta - Fevereiro 2025 <br>São Paulo a Cancún, México<br>De: R$2364 - R$2961",
      link: {
        href: "https://www.skyscanner.net/transport/flights/gru/cun/20250728/20250803/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-cancun-mexico&utm_medium=email&utm_source=acumbamail",
        target: "_blank",
        rel: "noopener noreferrer",
        title: "Oferta de voo de São Paulo a Cancún, México por R$2364",
        ariaLabel: "Voo direto de São Paulo a Cancún, México por R$2364"
      }
    },
    {
      id: "havana",
      image: havana,
      destination: "Havana, Cuba",
      details: "Ida e Volta - Março a Maio 2025<br>Lisboa a Havana, Cuba<br>De  534 € - 559 €",
      link: {
        href: "https://www.skyscanner.net/transport/flights/lis/hav/20250427/20250509/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-havana-cuba&utm_medium=email&utm_source=acumbamail",
        target: "_blank",
        rel: "noopener noreferrer",
        title: "Oferta de voo de Lisboa a Havana, Cuba por 534€",
        ariaLabel: "Voo direto de Lisboa para havana, Cuba por 534€"
      }
    },
    {
      id: "milao",
      image: milan,
      destination: "Milão, Itália",
      details: "Ida e Volta - Março a Novembro 2025<br>Porto a Milão, Itália<br>De: 38€ - 49€",
      link: {
        href: "https://www.skyscanner.net/transport/flights/opo/mxp/20250506/20250513/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-porto-para-milao-italia&utm_medium=email&utm_source=acumbamail",
        target: "_blank",
        rel: "noopener noreferrer",
        title: "Oferta de voo de Porto a Milão, Itália por 38€",
        ariaLabel: "Voo direto de Porto a Milão, Itália por 38€"
      }
    },
  ];

  if (isLoading) return <div>Loading...</div>;

  return (
    <CurrentOffersSection>
      <h2>Ofertas <Highlight>Premium</Highlight></h2>
      <LazyLoad height={800} offset={300} once>
        <OffersContainer>
          {offers.map((offer, index) => (
            <OfferCard key={index} onClick={() => handleClick(offer)}>
              <OfferImage src={offer.image} alt={offer.destination} />
              <OfferTextWrapper>
                <OfferTitle dangerouslySetInnerHTML={{ __html: offer.details }} />
                <OfferDescription>{offer.destination}</OfferDescription>
              </OfferTextWrapper>
            </OfferCard>
          ))}
        </OffersContainer>
      </LazyLoad>
    </CurrentOffersSection>
  );
};

export default MainCurrentOffers;


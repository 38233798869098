import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import Slide from '../components/Slide';

import losangeles from '../images/los-angeles2.webp';
import miami from '../images/miami.webp';
import tunis from '../images/tunis.webp';
import bh from '../images/belo-horizonte.webp';
import vitoria from '../images/vitoria2.webp';
import puntacana from '../images/punta-cana.webp';
import miami2 from '../images/miami2.webp';
import jeddah from '../images/jeddah.webp';
import bissau from '../images/bissau.webp';
import sanjose from '../images/san-jose-costa-rica.webp';
import roma from '../images/roma.webp';
import barcelona from '../images/barcelona.webp';
import cancun from '../images/cancun.webp';

const CurrentOffersSection = styled.section`
  margin: 2rem 0;
  text-align: center;
  h2 {
    font-family: 'Poppins';
    font-size: 3vw;
    color: #2980B9;
    padding: 2rem;
  }
`;

const Highlight = styled.span`
  color: #FF5733;
`;
const MoreCurrentOffers = () => {
  const offers = [
    {
      image: losangeles,
      details: "Ida e Volta - Março a Dezembro 2025<br>São Paulo a Los Angeles, Estados Unidos<br>De: R$2635- R$3521",
      externalLink: "https://www.skyscanner.net/transport/flights/gru/lax/20251102/20251116/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-los-angeles-estados-unidos&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: miami,
      details: "Ida e Volta - Maio a Janeiro 2026<br>São Paulo a Miami, Estados Unidos<br>De: R$2227",
      externalLink: "https://gr.skyscanner.com/transport/flights/gru/mia/250811/250823/?adultsv2=1&cabinclass=economy&childrenv2=&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&previousCultureSource=COOKIE&redirectedFrom=www.skyscanner.com&ref=home&rtn=1&utm_campaign=nova-oferta-de-sao-paulo-para-miami&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: tunis,
      details: "Ida e Volta (1 Paragem)  - Abril a Outubro 2025<br>Lisboa a Túnis<br>De: 184€ a 227€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/tun/20250403/20250408/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-tunis-tunisia&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: bh,
      details: "Ida e Volta - Março 2025 e Novembro 2025<br>São Paulo a Belo Horizonte<br>De: De R$ 383",
      externalLink: "https://www.skyscanner.net/transport/flights/gru/cnf/20251030/20251103/?adultsv2=1&cabinclass=economy&childrenv2=&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1&utm_campaign=nova-oferta-de-sao-paulo-para-belo-horizonte-brasil&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: vitoria,
      details: "Ida e Volta - Março 2025 a Setembro 2025<br>Rio de Janeiro a Vitória<br>De: De R$ 398",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/vix/20250829/20250907/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-vitoria-brasil&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: puntacana,
      details: "Ida e Volta - Janeiro a Setembro 2025<br>Rio de Janeiro a Punta Cana<br>De: R$2055 a R$2369",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/puj/20250324/20250330/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-punta-cana-republica-dominicana&utm_medium=email&utm_source=acumbamail",
    },  
    {
      image: miami2,
      details: "Ida e Volta - Fevereiro a Outubro 2025<br>Lisboa a Miami<br>De 380€ - 554€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/mia/20250423/20250504/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-miami-estados-unidos&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: jeddah,
      details: "Ida e Volta - Janeiro a Maio 2025<br>Lisboa a Jeddah<br>De: 193 a 264€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/jed/20250511/20250516/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-lisboa-para-jeddah-arabia-saudita&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: bissau,
      details: "Ida e Volta - Março a Maio 2025<br>Lisboa a Bissau<br>A partir de 260€",
      externalLink: "https://gr.skyscanner.com/transport/flights/lis/oxb/250322/250401/?adultsv2=1&cabinclass=economy&childrenv2=&currency=eur&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&previousCultureSource=COOKIE&redirectedFrom=www.skyscanner.com&ref=home&rtn=1&utm_campaign=nova-oferta-de-lisboa-para-bissau&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: sanjose,
      details: "Ida e Volta - Março 2025 a Outubro 2025<br>Rio De Janeiro a San José, Costa Rica<br>De: R$ 2,025",
      externalLink: "https://www.skyscanner.net/transport/flights/gig/sjo/20250403/20250415/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-rio-de-janeiro-para-san-jose-costa-rica&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: roma,
      details: "Ida e Volta - Março 2025 a Dezembro 2025<br>São Paulo a Roma<br>De: R$ 3,977",
      externalLink: "https://www.skyscanner.net/transport/flights/gru/fco/20250413/20250504/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-roma-italia&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: barcelona,
      details: "Ida e Volta - Março 2025 a Outubro 2025<br>Lisboa a Barcelona<br>De: 35€ a 48€",
      externalLink: "https://www.skyscanner.net/transport/flights/lis/bcn/20250321/20250402/?adultsv2=1&cabinclass=economy&childrenv2=&inboundaltsenabled=false&outboundaltsenabled=false&preferdirects=false&ref=home&rtn=1&utm_campaign=nova-oferta-de-lisboa-para-barcelona-espanha&utm_medium=email&utm_source=acumbamail",
    },
    {
      image: cancun,
      details: "Ida e Volta - Abril a Agosto 2025<br>São Paulo a Cancún<br>De: R$ 2,364",
      externalLink: "https://www.skyscanner.net/transport/flights/gru/cun/20250728/20250803/?adultsv2=1&cabinclass=economy&childrenv2=&ref=home&rtn=1&preferdirects=false&outboundaltsenabled=false&inboundaltsenabled=false&utm_campaign=nova-oferta-de-sao-paulo-para-cancun-mexico&utm_medium=email&utm_source=acumbamail",
    },
  ];

  const handleButtonClick = (offer) => {
    if (offer.externalLink) {
      window.open(offer.externalLink, '_blank');
    } else {
      console.error("No valid externalLink for this offer.");
    }
  };

  return (
    <CurrentOffersSection>
      <h2>Outras Ofertas <Highlight>Premium</Highlight></h2>
      <LazyLoad height={200} offset={100} once>
        <Slide offers={offers} handleButtonClick={handleButtonClick} />
      </LazyLoad>
    </CurrentOffersSection>
  );
};

export default MoreCurrentOffers;
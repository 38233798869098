//contentPT
const contentPT = {
  seo: {
    title: "Subscreve ao Clube de Voos - Encontra as Melhores Ofertas de Voos",
    description: "Subscreve ao Clube de Voos e recebe as melhores ofertas de voos diretamente no teu email. Ofertas personalizadas para o teu aeroporto de partida.",
    keywords: "subscrever,plano premium, clube de voos, ofertas de voos, voos baratos, promoções de voos",
    image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-apasaric-1285625.jpg?alt=media&token=aad01747-e91d-432b-b5ae-473517ed6c48",
  },
  popup: "Um email de verificação foi enviado. Por favor, verifica a tua caixa de correio electrónico e clica no link para ativar a subscrição.",
  tableid: "prctbl_1QTRIXH23jJ1vudCSKoQDVFb",
};

export default contentPT;

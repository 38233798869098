import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #EAF0F8;
  color: #2980B9;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media (min-width: 768px) {
    position: sticky;
    top: 0;
  }
`;

const FooterContent = styled.div`
  max-width: 87.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

const FooterText = styled.p`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Stack sentences vertically only on smaller screens */
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const CopyrightText = styled.span`
  font-size: 1rem; /* Default font size */

  /* Reduce font size on mobile screens */
  @media (max-width: 767px) {
    font-size: 0.875rem; /* Adjust this value as needed */
  }
`;

const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  gap: 5rem;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 0.85rem;
    gap: 1rem;
  }
`;

const FooterLink = styled.a`
  color: #2980B9;
  text-decoration: none;
  margin-left: 10px;

  &:hover {
    text-decoration: underline;
    color: #FF5733;
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
      <FooterLinksContainer>
        <FooterLink 
          href="/tcs-e-privacidade" 
          aria-label="Termos, Condições e Política de Privacidade do Clube de Voos"
        >
          T&Cs e Política de Privacidade
        </FooterLink>

        <FooterLink 
          href="mailto:apoio@clubedevoos.com" 
          aria-label="Enviar um email para o suporte do Clube de Voos"
        >
          Contacto
        </FooterLink>

        <FooterLink 
          href="/sorteio" 
          aria-label="Detalhes sobre sorteios e promoções do Clube de Voos"
        >
          Sorteio e Promoções
        </FooterLink>
      </FooterLinksContainer>
        <FooterText>
          <CopyrightText>
            &copy; {currentYear} Clube de Voos. Todos os direitos reservados.
          </CopyrightText>
          <FooterLink 
            href="https://www.bestsites0x.com" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Desenvolvido pela BestSites0x, agência especializada em web design"
            title="Desenvolvido pela BestSites0x, especialista em web design"
          >
            Feito com <span role="img" aria-label="heart">❤️</span> por BestSites0x - Web Design
          </FooterLink>
        </FooterText>

      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { firestore } from '../firebaseConfig';
import HotCurrentOffers from '../components/HotCurrentOffers';
import LanguageContext from '../context/LanguageContext';
import contentPT from '../content/dicas/contentPT';
import contentBR from '../content/dicas/contentBR';

const DicasContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem 1rem;
  gap: 1.2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const LeftSection = styled.div`
  flex: 1 1 25%;
  max-width: 16rem;
  position: relative;
  font-family: 'Nunito';
  font-size: 1.5vw;
  font-weight: 500;
  color: #2980B9;
  text-align: center;

  .highlight {
    color: #FF5733;
  }
    
  @media (max-width: 768px) {
    width: 100%;
    flex: 1 1 100%;
    max-width: 100%;
    font-size: 5vw;
    text-align: center;
    order: 2; /* Ensure LeftSection comes second */
  }
`;

const StickyOffersContainer = styled.div`
  position: sticky;
  top: 20rem;
  
  @media (max-width: 768px) {
    position: relative;
    top: 0;
  }
`;

const MiddleSection = styled.div`
  flex: 1 1 60%;
  font-family: 'Poppins';
  font-size: 2vw;
  font-weight: 500;
  color: #2980B9;
  text-align: center;

  .highlight {
    color: #FF5733;
  }

  @media (max-width: 768px) {
    font-size: 8vw;
    order: 1; /* Ensure MiddleSection comes first */
  }
`;

const RightSection = styled.div`
  flex: 1 1 15%;

  @media (max-width: 768px) {
    order: 3; /* Ensure RightSection comes last */
  }
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-size: 4vw;
  font-weight: 600;
  color: #2980b9;
  text-align: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 7vw;
  }
`;

const SearchBar = styled.input`
  width: 97%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: 0.1rem solid #ccc;
`;

const BlogListing = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BlogItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  text-decoration: none;
`;

const BlogImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
`;

const BlogTitle = styled.h3`
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  color: #ff5733;
  margin-top: 5px 0;
`;

const BlogExcerpt = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 400;
  color: #0a1111;
  margin-bottom: 5px;
`;

const BlogAuthor = styled.p`
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 500;
  color: #2980b9;
  text-align: left;
  width: 100%;
  margin-left: 0;
`;

const Pagination = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;
`;

const PaginationButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2980b9;
  color: #fff;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Sidebar = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 20rem;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
  }
`;

const SidebarHeading = styled.h2`
  font-family: 'Poppins';
  font-size: 2vw;
  font-weight: 500;
  color: #2980B9;
  text-align: center;

  .highlight {
    color: #FF5733;
  }

  @media (max-width: 768px) {
    font-size: 8vw  ;
  }
`;

const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const SubscriptionInput = styled.input`
  width: 80%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Source Sans Pro';
  font-size: 1rem;
`;

const StyledSelect = styled.select`
  width: 86%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Source Sans Pro';
  box-sizing: border-box;
`;

const SubscriptionButton = styled.button`
  background-color: #ff5733;
  color: #fff;
  border: none;
  width: 86%;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 700;
  font-size: 1.6rem;
  font-family: 'Nunito';
  letter-spacing: 0.05rem;

  &:hover {
    background-color: #f39c12;
  }
`;

const ConfirmationPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: 30rem;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #2980B9;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  z-index: 1000;
`;

const PopupContent = styled.div`
  text-align: center;
`;

const PremiumLink = styled.a`
  font-family: 'Nunito';
  font-size: 1rem;
  color: #2980B9;
  text-decoration: underline;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const Highlight = styled.span`
  color: #FF5733;
`;

const departureAirports = [
  "LISBOA-PT",
  "PORTO-PT",
  "RIO DE JANEIRO-BR",
  "SÃO PAULO-BR",
];

function stripHtmlTags(content) {
  const div = document.createElement("div");
  div.innerHTML = content;
  return div.textContent || div.innerText || "";
}
const Dicas = () => {
  const { language } = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const postsPerPage = 6;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [homeAirport, setHomeAirport] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const importAllPosts = async () => {
      const context = require.context('../posts', false, /\.json$/);
      const posts = await Promise.all(
        context.keys().map(async (key) => {
          const post = await import(`../posts/${key.slice(2)}`);
          return post;
        })
      );
      setPosts(posts.sort((a, b) => new Date(b.creationTime) - new Date(a.creationTime)));
    };

    importAllPosts().catch((error) => console.error('Error loading blog posts:', error));
  }, []);

  const handleSearch = debounce((event) => {
    setSearchTerm(event.target.value);
  }, 300);

  const filteredPosts = posts.filter((post) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, 'temporaryPassword');
      const user = userCredential.user;
      await sendEmailVerification(user);

      await setDoc(doc(firestore, 'subscribers', user.uid), {
        name,
        email,
        homeAirport,
        subscriptionType: 'free',
        isVerified: false,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      setShowPopup(true);
      setName('');
      setEmail('');
      setHomeAirport('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Ocorreu um erro. Por favor, tenta novamente.');
    }
  };

  return (
    <DicasContainer>
      <Helmet>
        <title>{content.seo.title}</title>
        <meta name="description" content={content.seo.description} />
        <meta name="keywords" content={content.seo.keywords} />
        <meta property="og:title" content={content.seo.title} />
        <meta property="og:description" content={content.seo.description} />
        <meta property="og:image" content={content.seo.image} />
        <meta property="og:url" content="https://clubedevoos.com/dicas" />
        <link rel="canonical" href="https://clubedevoos.com/dicas" />
      </Helmet>
      
      <LeftSection>
        <StickyOffersContainer>
          <h2 className="current-offers-title">Ofertas <Highlight>Quentes</Highlight></h2>
          <HotCurrentOffers />
        </StickyOffersContainer>
      </LeftSection>
      <MiddleSection>
        <Title>Dicas para Viajantes</Title>
        <SearchBar
          type="text"
          placeholder="Procurar..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <BlogListing>
          {currentPosts.map((post) => (
            <BlogItem to={`/blog/${post.id}`} key={post.id}>
              <LazyLoad height={200} offset={100} once>
                <BlogImage src={post.image} alt={post.title} />
              </LazyLoad>
              <BlogTitle>{post.title}</BlogTitle>
              <BlogExcerpt>{stripHtmlTags(post.content).substring(0, 61)}...</BlogExcerpt>
              <BlogAuthor>{post.author}</BlogAuthor>
            </BlogItem>
          ))}
        </BlogListing>
        <Pagination>
          <PaginationButton onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </PaginationButton>
          <PaginationButton onClick={() => paginate(currentPage + 1)} disabled={indexOfLastPost >= filteredPosts.length}>
            Next
          </PaginationButton>
        </Pagination>
      </MiddleSection>
      <RightSection>
        <Sidebar>
          <SidebarHeading>Subscreve ao<br /> <span className="highlight">Clube de Voos</span><br /></SidebarHeading>
          <SubscriptionForm onSubmit={handleSubmit}>
          <SubscriptionInput 
                  type="text" 
                  placeholder="Nome" 
                  required 
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <SubscriptionInput 
                  type="email" 
                  placeholder="Email" 
                  required 
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <StyledSelect
                  value={homeAirport}
                  onChange={(e) => setHomeAirport(e.target.value)}
                  required
                >
                  <option value="">Seleciona o Aeroporto de Partida</option>
                  {departureAirports.map((airport, index) => (
                    <option key={index} value={airport}>{airport}</option>
                  ))}
            </StyledSelect>
            {/* Adiciona o Meu Aeroporto link */}
            <PremiumLink href={content.link} target="_blank">
              Adiciona o Meu Aeroporto
            </PremiumLink>
            <SubscriptionButton type="submit">Subscreve</SubscriptionButton>
          </SubscriptionForm>
        </Sidebar>
      </RightSection>
      {showPopup && (
      <ConfirmationPopup>
        <CloseButton onClick={() => setShowPopup(false)}>X</CloseButton>
        <PopupContent>
          <h2>Subscreve ao <Highlight>Clube de Voos</Highlight></h2>
          <p>Um email de verificação foi enviado. Por favor, verifica a tua caixa de correio electrónico e clica no link para ativar a subscrição.</p>
          <PremiumLink href="/subscreve#price-table-heading" onClick={() => setShowPopup(false)}>
            Quero Premium!
          </PremiumLink>
        </PopupContent>
      </ConfirmationPopup>
    )}
    </DicasContainer>
  );
};

export default Dicas;

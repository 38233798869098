//contentPT
const contentPT = {
  intro: "Bem-vindo(a) ao Clube De Voos. Estamos empenhados em proteger as tuas informações pessoais e o teu direito à privacidade. Esta Política de Privacidade explica como recolhemos, utilizamos, divulgamos e protegemos a tua informação quando utilizas o nosso serviço.",
  info: {
    heading: "2. Informações que Recolhemos",
    text: "Recolhemos as seguintes informações:",
    list: "Incluindo o teu aeroporto de origem, alturas de viagem preferidas, destinos, companhias aéreas, orçamentos e atividades.",
  },
  collection: {
    heading: "3. Como Recolhemos as Informações",
    list1: "Diretamente quando subscreves ao nosso serviço.",
    list2: "Quando completas o teu perfil de viajante (para serviço Premium).",
  },
  usage: {
    heading: "4. Como Utilizamos as Tuas Informações",
    text: "Utilizamos as tuas informações para:",
    list1: "Enviar a nossa newsletter e alertas de viagem.",
    list2: "Personalizar recomendações de viagem com base nas tuas preferências.",
    list3: "Verificar a tua identidade e gerir a tua conta (para serviço Premium)",
    list4: "Melhorar os nossos serviços e experiência do utilizador.",
  },
  payment: {
    heading: "5. Informações de Pagamento",
    text: "Todo o processamento de pagamentos para subscrições Premium é gerido pela Stripe. Não recolhemos, armazenamos ou processamos quaisquer informações de pagamento nos nossos servidores."
  },
  retention: {
    heading: "6. Retenção e Eliminação de Dados",
    text: "Retemos as tuas informações apenas pelo tempo necessário para fornecer os nossos serviços. Se optares por cancelar a tua subscrição, eliminaremos todas as informações que temos sobre ti dos nossos registos."
  },
  security: {
    heading: "7. Segurança dos Dados",
    text: "Implementamos medidas técnicas e organizacionais apropriadas para proteger as tuas informações pessoais contra processamento não autorizado ou ilegal, perda acidental, destruição ou danos."
  },
  cookies: {
    heading: "8. Cookies e Tecnologias Similares",
    text: "Não usamos cookies para gerir as sessões dos nossos utilizadores ou para armazenar quaisquer das suas preferências. Não usamos cookies, ponto final."
  },
  rights: {
    heading: "9. Os Teus Direitos",
    text: "Tens o direito de:",
    list1: "Aceder às informações pessoais que temos sobre ti.",
    list2: "Corrigir quaisquer imprecisões nas tuas informações pessoais.",
    list3: "Solicitar a eliminação das tuas informações pessoais.",
    list4: "Optar por não receber comunicações de marketing.",
  },
  changes: {
    heading: "10. Alterações a Esta Política de Privacidade",
    text: "Podemos atualizar esta política de privacidade periodicamente. Quaisquer alterações a esta política de privacidade serão publicadas nesta página na secção 'Última Atualização' e enviamos uma notificação a todos os subscritores ativos sempre que haja uma alteração."
  },
  update: "11. Última Atualização",
  contact: {
    heading: "12. Contacta-nos",
    text: "Se tiveres quaisquer dúvidas sobre estes T&Cs ou Política de Privacidade, ou se desejares exercer os teus direitos para solicitar, eliminar ou atualizar os teus dados, por favor contacta-nos em:"
  },
  tctitle: "Termos e Condições (T&Cs)",
  refunds: {
  heading: "1. Subscrições e Reembolsos:",
  list1: "Não fazemos reembolsos para as assinaturas Premium, mas oferecemos um teste grátis de 14 dias (apenas para o plano vitalício) para experimentares os nossos serviços antes de decidir.",
  list2: "Oferecemos uma subscrição gratuita vitalícia para que possas experimentar os nossos serviços."
  },
  mkt: {
  heading: "2. Partilha de dados com parceiros:",
  list1: "Podemos partilhar os dados fornecidos por assinantes com os nossos parceiros para oferecer promoções e benefícios aos nossos assinates Premium.",
  list2: "Se és assinante gratuito, podes pedir para não partilharmos os teus dados.",
  },
  promos: {
  heading: "3. Promoções e Benefícios:",
  list1: "As promoções exclusivas dos parceiros são reservadas para assinantes Premium.",
  },
  airports: {
  heading: "4. Aeroportos de origem:",
  list1: "Cada email está associado a um único aeroporto de origem e não é possível registar o mesmo email a um aeroporto diferente.",
  list2: "Se fores assinante Premium, podes mudar o teu aeroporto de origem as vezes que quiseres. No futuro, podemos adicionar um intervalo entre mudanças (por exemplo, uma semana)."
  },
  tcprices: {
  heading: "5. Disponibilidade de Preços",
  text: "Os preços apresentados nas ofertas do Clube de Voos refletem os valores disponíveis no momento da publicação. No entanto, como os preços de voos podem mudar rapidamente, especialmente em casos de tarifas promocionais ou erros de tarifas (error fares), é importante que confirmes a disponibilidade o mais cedo possível.",
  list1: "Tarifas promocionais: Algumas ofertas são limitadas e podem esgotar-se em poucas horas.",
  list2: "Error fares: Estas tarifas, por serem erros pontuais das companhias aéreas, tendem a ser corrigidas rapidamente e muitas vezes deixam de estar disponíveis num curto espaço de tempo. Recomendamos que tomes decisões rápidas e estejas atento(a) às notificações para aproveitares as melhores oportunidades.",
  },
  tcchanges: {
  heading: "6. Alterações aos Termos e Condições:",
  list1: "O Clube De Voos pode alterar estes Termos e Condições. Caso isso aconteça, vamos avisar-te com antecedência.",
  },
};

export default contentPT;

// contentBR.js

const contentBR = {
  seo: {
    title: "Assine o Clube de Voos - Encontre as Melhores Ofertas de Voos",
    description: "Assine o Clube de Voos e receba as melhores ofertas de voos diretamente no seu email. Ofertas personalizadas para o seu aeroporto de partida.",
    keywords: "assinar, plano premium, clube de voos, ofertas de voos, voos baratos, promoções de voos",
    image: "https://firebasestorage.googleapis.com/v0/b/cdv---stagging-37d74.appspot.com/o/images%2Fpexels-apasaric-1285625.jpg?alt=media&token=aad01747-e91d-432b-b5ae-473517ed6c48",
  },  
  popup: "Um e-mail de verificação foi enviado. Por favor, verifique sua caixa de entrada e clique no link para ativar a assinatura.",
    tableid: "prctbl_1QTRCkH23jJ1vudCyjGOfBGX",
  };
  
  export default contentBR;
  
import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { Helmet } from 'react-helmet-async';
import Carousel from '../components/Carousel';
import MainCurrentOffers from '../components/MainCurrentOffers';
import SubscribePopup from '../components/SubscribePopup';
import contentPT from '../content/homepage/contentPT';
import contentBR from '../content/homepage/contentBR';
import LanguageContext from '../context/LanguageContext';

import image1 from '../images/night-skyline.webp';
import image2 from '../images/backpackers1.webp';
import image3 from '../images/egypt.webp';
import image4 from '../images/air-ballon.webp';
import image6 from '../images/nova-iorque.webp';
import image7 from '../images/zakynthos.webp';

// ====== STYLED COMPONENTS ====== //
const Container = styled.div`
  padding: 0px;
`;

const HeroSection = styled.section`
  position: relative;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: center;
  text-align: center;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 32rem;
  }
`;

const HeroText = styled.div`
  position: absolute;
  top: 2%; 
  left: 8%;
  right: 8%;
  text-align: center;
  color: #FFFFFF; 
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; 
  @media (max-width: 1024px) {
    top: 8%;
    gap: 1.5rem;
  }

  @media (max-width: 768px) {
    top: 2%;
    gap: 1rem;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins';
  font-size: 7.5vw;
  color: #FF5733; 
  text-transform: uppercase;
  margin-bottom: 8.5rem; 
  margin-top: 2rem;
  text-shadow: 0.7rem -0.23rem 0.6rem rgba(15, 9, 9, 0.3); 
  @media (max-width: 1024px) {
    font-size: 10vw;
    margin-bottom: 0.75rem;
  }
  @media (max-width: 768px) {
    font-size: 9vw;
    margin-bottom: 11.5rem;
  } 
`;

const HeroTextP = styled.p`
  font-family: 'Nunito';
  font-size: 2rem;
  font-weight: 700;
  padding-top: 0.01rem;
  -webkit-text-stroke: 0.4px #EAF0F8; 
  text-shadow: 
    -0.4px -0.4px 0 #EAF0F8,  
    0.4px -0.4px 0 #EAF0F8,
    -0.4px 0.4px 0 #EAF0F8,
    0.4px 0.4px 0 #EAF0F8; 
  width: 100%; 
  margin: 0 auto; 
  letter-spacing: 0.1rem;
  @media (max-width: 1024px) {
    font-size: 1.3rem; 
  }
  @media (max-width: 768px) {
    font-size: 0.8rem; 
    font-weight: 500;
    width: 100%; 
    padding-top: 0.2rem;
  }
`;

const HeroButton = styled.button`
  font-family: 'Nunito';
  font-size: 1.4rem;
  font-weight: 700;
  color: #F39C12;
  background: #2980B9;
  border: none;
  padding: 1.1rem 1.4rem;
  border-radius: 1.5rem;
  cursor: pointer;
  margin-top: 2.5rem; 

  &:hover {
    background: #F39C12;
    color: #0A1111;
  }
  @media (max-width: 1024px) {
    font-size: 1.2rem;
    padding: 0.9rem 1.2rem;
    margin-top: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.7rem 1rem;
    margin-top: 2.5rem;
  }
`;

const ServicesSection = styled.section`
  text-align: center;
  padding: 2rem;
  background-color:  #EAF0F8;
  h2 {
    font-family: 'Poppins';
    font-size: 4.5vw;
    color: #2980B9;
  }
`;

const ServiceCards = styled.div`
  display: grid;
  gap: 2rem;
  margin-top: 1rem;
  padding: 2rem;
  justify-content: center;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(250px, 300px));
    grid-template-rows: auto auto;
    grid-template-areas:
      "card1 card2 card3"
      ". card4 card5";
    
    & > *:nth-child(1) { grid-area: card1; transform: translateY(50px) translateX(-80px); }
    & > *:nth-child(2) { grid-area: card2; transform: translateY(10px) translateX(20px); }
    & > *:nth-child(3) { grid-area: card3; transform: translateY(50px); }
    & > *:nth-child(4) { grid-area: card4; transform: translateX(-250px); }
    & > *:nth-child(5) { grid-area: card5; transform: translateX(120px) translateY(-5px); }
  }
`;

const ServiceCard = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 32rem;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: 'Sans Source Pro';
  font-size: 1.3rem;
  color: white;
  opacity: 0.8;

  &:nth-child(1),
  &:nth-child(5) {
    background-color: #2980B9; /* Blue */
  }
  &:nth-child(3),
  &:nth-child(4) {
    background-color: #FF5733; /* Orange */
  }
  &:nth-child(2) {
    background-color: #F39C12; /* Yellow */
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CurrentOffersSection = styled.section`
  margin: 2rem 0;
  text-align: center;
  h2 {
    font-family: 'Poppins';
    font-size: 5vw;
    color: #2980B9;
    padding: 2rem;
  }
  @media (max-width: 768px) {
    width: 100%; 
  }
`;

const StyledButton = styled.button`
  font-family: 'Nunito';
  font-size: 1.4rem;
  font-weight: 700;
  color: #F39C12;
  background: #2980B9;
  border: none;
  padding: 1.1rem 1.4rem;
  border-radius: 1.5rem;
  cursor: pointer;
  margin-top: 2.5rem;

  &:hover {
    background: #F39C12;
    color: #0A1111;
  }
  @media (max-width: 1024px) {
    font-size: 1.2rem;
    padding: 0.9rem 1.2rem;
    margin-top: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.7rem 1rem;
    margin-top: 2.5rem;
  }
`;

const Homepage = () => {
  const { language } = useContext(LanguageContext);
  const content = language === 'BR' ? contentBR : contentPT;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const servicesRef = useRef(null);
  const shuffledRef = useRef(false); // to ensure we shuffle only once

  // State to store card info. You can define each card’s label/color/heading in the array.
  const [cards, setCards] = useState([
    {
      key: 1,
      heading: content.ofertasexclusivas.heading,
      text: content.ofertasexclusivas.text,
    },
    {
      key: 2,
      heading: content.promoções.heading,
      text: content.promoções.text,
    },
    {
      key: 3,
      heading: content.acesso.heading,
      text: content.acesso.text,
    },
    {
      key: 4,
      heading: content.tarifas.heading,
      text: content.tarifas.text,
    },
    {
      key: 5,
      heading: content.opções.heading,
      text: content.opções.text,
    }
  ]);

  // Helper to shuffle an array
  const shuffleArray = (arr) => {
    const newArr = [...arr];
    for (let i = newArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
    }
    return newArr;
  };

  useEffect(() => {
    const handleScroll = () => {
      // If we haven't shuffled yet
      if (!shuffledRef.current && servicesRef.current) {
        const rect = servicesRef.current.getBoundingClientRect();

        // "Bottom" is how far from top of viewport
        // "window.innerHeight" is the visible window height

        // We want the user to have fully seen the cards, and then scroll 40% past them
        // So if (rect.bottom) is 40% above the bottom of the screen
        // you can tweak the condition below to your liking:
        if (rect.bottom < window.innerHeight * 0.95) {
          // Shuffle once
          setCards((prev) => shuffleArray(prev));
          shuffledRef.current = true; // mark done
        }
      }
    };

    // Attach scroll listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);  

  const debouncedOpenPopup = debounce(() => setIsPopupOpen(true), 300);
  const debouncedClosePopup = debounce(() => setIsPopupOpen(false), 300);

  const homepageImages = [
    {
      src: image1,
      alt: "Night Skyline",
      srcSet: `${image1} 500w, ${image1} 1000w, ${image1} 1500w`,
    sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
  },
    {
      src: image2,
      alt: "Backpackers",
      srcSet: `${image2} 500w, ${image2} 1000w, ${image2} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
    {
      src: image3,
      alt: "Egypt",
      srcSet: `${image3} 500w, ${image3} 1000w, ${image3} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
    {
      src: image4,
      alt: "Air Ballons",
      srcSet: `${image4} 500w, ${image4} 1000w, ${image4} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
    {
      src: image6,
      alt: "Nova Iorque",
      srcSet: `${image6} 500w, ${image6} 1000w, ${image6} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
    {
      src: image7,
      alt: "Zakynthos",
      srcSet: `${image7} 500w, ${image7} 1000w, ${image7} 1500w`,
      sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw",
    },
  ];  

  console.log('Homepage Images:', homepageImages);

  return (
    <Container>
      {/* Add Helmet for SEO */}
      <Helmet>
        <title>{content.seo.title}</title>
        <meta name="description" content={content.seo.description} />
        <meta name="keywords" content={content.seo.keywords} />
        <meta property="og:title" content={content.seo.title} />
        <meta property="og:description" content={content.seo.description} />
        <meta property="og:image" content={content.seo.image} />
        <meta property="og:url" content="https://clubedevoos.com" />
        <link rel="canonical" href="https://clubedevoos.com" />
      </Helmet>

      <HeroSection>
        <Carousel imageUrls={homepageImages} />
        <HeroText>
          <Title>{content.title}</Title>
          <HeroTextP>{content.herotext}</HeroTextP>
          <HeroTextP>{content.herotext2}</HeroTextP>
          <HeroButton 
            onClick={debouncedOpenPopup} 
            aria-label={content.buttonaria}
          >
            Quero ofertas incríveis! (Grátis)
          </HeroButton>
        </HeroText>
      </HeroSection>

      {/* Services Section */}
      <ServicesSection ref={servicesRef}>
        <h2>{content.services}</h2>
        <ServiceCards>
          {cards.map((card) => (
            <ServiceCard key={card.key}>
              <h4>{card.heading}</h4>
              <p>{card.text}</p>
            </ServiceCard>
          ))}
        </ServiceCards>
      </ServicesSection>

      {/* Current Offers */}
      <CurrentOffersSection>
        <MainCurrentOffers />
        <StyledButton
          onClick={() => window.location.href = '/ofertas-premium'}
          aria-label={content.buttonariaPremium}
        >
          Mais Ofertas Premium
        </StyledButton>
      </CurrentOffersSection>

      {/* Popup */}
      <SubscribePopup isOpen={isPopupOpen} onClose={debouncedClosePopup} />
    </Container>
  );
};

export default Homepage;
